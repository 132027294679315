if(module.hot) {
    module.hot.accept();
}

import 'selectize';
import Shuffle from 'shufflejs';
import Swiper from 'swiper';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const body = $('body');
const header = $('header.header');
const wptheme = jQuery('input#WPtheme').val();
const targetElement = document.querySelector("#main-menu");

let winOS = true;
let OSName = 'WinOS';

$(document).ready(function() {
    $("body").removeClass("preload");
});

//////////////////////
//   Init
//////////////////////
openCloseMenu();
goToTop();




////////////////////
//   Google Map
////////////////////
if( body.hasClass('page-template-template-contact') ) {

    //var gstyles = [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#000000"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#000000"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#463e49"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#413944"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#514954"},{"lightness":"0"},{"gamma":"1"},{"weight":"2.5"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#514954"},{"lightness":"0"},{"gamma":"1"},{"weight":"0.7"}]},{"featureType":"road.local","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"lightness":"-60"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#372f3a"},{"lightness":"1"},{"gamma":"1"}]}];

    var address = jQuery('input#adresse').val();
    // console.log(address);

    // When the window has finished loading create our google map below
    google.maps.event.addDomListener(window, 'load', init);

    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 16,
            scrollwheel: false,
            // The latitude and longitude to center the map (always required)
            //center: new google.maps.LatLng(48.058632, -2.969668),
            disableDefaultUI: true,

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            //styles: gstyles
        };

        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('gmap');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        let geocoder = new google.maps.Geocoder();
        geocoder.geocode( { 'address': address }, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                    icon: '/wp-content/themes/' + wptheme + '/assets/images/marker.png'
                });
            }
        });
    }

}



//////////////////////
//   Swiper
//////////////////////
let partenairesSwiper = new Swiper('.swiper-container-partenaires', {
    loop: true,
    speed: 1000,
    slidesPerView: 7,
    spaceBetween: 20,
    // watchSlidesProgress: true,
    breakpoints: {
        800: { // when window width is <= 800px
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 20,
        },
        1024: { // when window width is <= 1024px
            slidesPerView: 4,
            spaceBetween: 20,
            centeredSlides: false,
        },
        1600: { // when window width is <= 1600px
            slidesPerView: 5,
            spaceBetween: 20,
            centeredSlides: false,
        },
    },
    navigation: {
        nextEl: '.swiper-button-next-partenaire',
        prevEl: '.swiper-button-prev-partenaire',
    }
});

let gallerySwiper = new Swiper('.wp-block-opta-block-swiper-gallery .swiper-container', {
    loop: true,
    speed: 1000,
    slidesPerView: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});

if( $('.competitions-content').length ) {
    let navigation = '<div class="navigation"><div class="swiper-button-prev"/><div class="swiper-button-next"/></div>'
    $(".list_cal .evcal_month_line").each(function(index) {
        $(this).next(".evcal_list_month").addBack().wrapAll("<div class='swiper-slide evcal_list-slide' />")
    });
    $('.competitions-content').find('.list_cal').addClass('swiper-wrapper').wrap('<div class="swiper-container swiper-container-competitions" />');
    $('.competitions-content').find('.swiper-container-competitions').prepend(navigation);
}

let competitionsSwiper = new Swiper('.swiper-container-competitions', {
    loop: true,
    speed: 1000,
    slidesPerView: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});



// PopUp Parcours
if( $(body).is('.le-parcours') ) {
    $('.blocks-gallery-item')
}



// Bug Widget Météo. Il rajoute une balise <p> avant et après.
if( $('.wpc-weather-id').length ) {
    if( ! $('.wpc-weather-id').prev().html().length ) {
        $('.wpc-weather-id').prev().remove();
    }
    if( ! $('.wpc-weather-id').next().html().length ) {
        $('.wpc-weather-id').next().remove();
    }
}


let targetBlank = $('.wp-block-button.blank');
targetBlank.find('a').attr('target', '_blank');


//////////////////////
//   Hauteur Fold
//////////////////////
$('.home header.header .bgHeader').css('height', getWindowHeight());

$(window).on('resize', function(){
    if( body.is('.opened-menu') ) {
        $('.burger').trigger('click');
    }
});



//////////////////////
//   Selectize
//////////////////////
let selectTerms = $('select#selectTerms').selectize({
    maxItems: 1,
    render: {
        option: function(item, escape) {
            return '<div class="option"><span>'+item.text+'</span> </div>'
            //+ console.log(item);
        }
    },
    // closeAfterSelect: true,
    // sortField: {
    //     field: 'text',
    //     direction: 'asc'
    // },
});
let selectizeTerms;
if(selectTerms.length) {
    selectizeTerms = selectTerms[0].selectize;
}
$('#selectTerms-selectized').prop('disabled', true);



//////////////////////
//   Shuffle
//////////////////////
let shuffleElement = document.querySelector('.news-shuffle-container');

let shuffleInstance;

if(shuffleElement) {

    shuffleInstance = new Shuffle(shuffleElement, {
        itemSelector: '.post-item',
        useTransforms: false,
    });

    $('ul.terms').on('click', 'li.term', function(e){
        handleFilter(e, shuffleInstance, false, selectizeTerms);
    });
    $('#selectTerms').change(function(e){
        handleFilter(e, shuffleInstance, true);
    });

}

if( $('.monthsToShow').length ) {
    let nbrMonths2ShowClass = $('.monthsToShow').attr('class');
    let nbrMonths2Show = nbrMonths2ShowClass.substring(
        nbrMonths2ShowClass.lastIndexOf(" ") + 1,
        nbrMonths2ShowClass.lastIndexOf("-")
    );
    $('.monthsToShow').append(monthsToShow(nbrMonths2Show));
}



//////////////////////
//   Load More
//////////////////////
let $loadMore = body.find(".loadMore");
let post_type = $loadMore.data('type');
let offset;
let countPosts, ppp, screenReader, liste, defaultCard =  '';

if(post_type === 'post') {
    screenReader = screenReaderNews;
    countPosts = screenReader.count_posts;
    ppp = screenReader.ppp;
    liste = body.find(".wrapArticles");
}
console.log(ppp);

if ( $loadMore.length ) {

    let loadMore = function () {

        $loadMore.toggleClass("loading", true);

        offset = liste.children().length;

        jQuery.ajax({
            type: "POST",
            dataType: "html",
            url: screenReader.ajaxurl,
            data: {
                'post_type': post_type,
                'offset': offset,
                'ppp': ppp,
                'action': 'more_post_ajax'
            }
        }).done(function (data, status, request) {

            $loadMore.toggleClass("loading", false);
            // console.log(offset);
            // console.log( countPosts );
            // console.log(data);
            let $data = jQuery(data);
            if ($data.length > 0) {
                // let $newElements = $data.css({ opacity: 0 });
                let $newElements = $data;
                liste.append($newElements);
                // $newElements.animate({ opacity: 1 });
                $loadMore.find("span.textLabel").html(screenReader.loadmore);
                $loadMore.toggleClass("disabled", false);
                offset = liste.children().length;
                if (offset >= countPosts) {
                    $loadMore.toggleClass("disabled", true);
                    $loadMore.find("span.textLabel").html(screenReader.no_more);
                }

                if(shuffleInstance) {
                    // Save the total number of new items returned from the API.
                    var itemsFromResponse = $data.length;
                    console.log($data);
                    // Get an array of elements that were just added to the grid above.
                    var allItemsInGrid = Array.from(liste.children());
                    // var allItemsInGrid = $data.toArray();
                    console.log(allItemsInGrid);
                    // Use negative beginning index to extract items from the end of the array.
                    var newItems = allItemsInGrid.slice(-itemsFromResponse);
                    // console.log(newItems);
                    shuffleInstance.add(newItems);
                    /**
                     * Convert an object to HTML markup for an item.
                     * @param {object} dataForSingleItem Data object.
                     * @return {string}
                     */
                    function getMarkupFromData(dataForSingleItem) {
                        var name = dataForSingleItem.first_name + ' ' + dataForSingleItem.last_name;
                        // https://www.paulirish.com/2009/random-hex-color-code-snippets/
                        var randomColor = ('000000' + Math.random().toString(16).slice(2, 8)).slice(-6);
                        return [
                            '<div class="js-item col-3@xs col-3@sm person-item" data-id="' + dataForSingleItem.id + '">',
                            '<div class="person-item__inner" style="background-color:#' + randomColor + '">',
                            '<span>' + name + '</span>',
                            '</div>',
                            '</div>',
                        ].join('');
                    }

                    /**
                     * Convert an array of item objects to HTML markup.
                     * @param {object[]} items Items array.
                     * @return {string}
                     */
                    function getItemMarkup(items) {
                        return items.reduce(function (str, item) {
                            return str + getMarkupFromData(item);
                        }, '');
                    }

                    /**
                     * Append HTML markup to the main Shuffle element.
                     * @param {string} markup A string of HTML.
                     */
                    function appendMarkupToGrid(markup) {
                        liste.insertAdjacentHTML('beforeend', markup);
                    }
                }

                // if (navigator.appVersion.indexOf("Mac") === -1) {
                //     console.log('pcwin');
                //     setTimeout(function () {
                //         body.niceScroll().resize();
                //     }, 1000);
                // }

            }
            else {
                $loadMore.toggleClass("disabled", true);
                $loadMore.find("span.textLabel").html(screenReader.no_more);

            }
        });
    };

    // Charger +
    $loadMore.on("click", function () {
        jQuery(this).toggleClass("loading", true).find("span.textLabel").html(screenReader.loading);
        loadMore(offset, shuffleInstance);
    });
}




///////////////////////////////////
//   EventOn - Organizer Thumbs
///////////////////////////////////
$('.eventon_list_event').each(function(){
    console.log("EVENT");
    let event = $(this);
    let organizerImg = event.find('.evcal_eventcard .evo_card_organizer_image').html();
    console.log(organizerImg);
    event.find('.evcal_list_a').append(organizerImg);
});




//////////////////////
//   FUNCTIONS
//////////////////////
function openCloseMenu(winOS) {
    body.on('click', '.burger', function(e){
        if( ! body.is('.opened-menu')){
            disableBodyScroll(targetElement);
            $(body).removeClass('closed-menu').addClass('opened-menu');
        } else {
            enableBodyScroll(targetElement);
            // $('#top-nav').foundation('toggle', '.menu-item');
            $('#top-nav > li.menu-item ').attr('aria-expanded', false);
            $('#top-nav ul.submenu ').hide();
            $(body).removeClass('opened-menu').addClass('closed-menu');
        }
    } );
}

function handleFilter(evt, shuffleInstance, isSelect, seletizedSelect) {

    let filterGroup = '';

    if(isSelect) {
        const option = evt.currentTarget;
        const termGroup = option.selectize.$input["0"].value;
        $('li.term').removeClass('active');
        $('li.term[data-group="'+termGroup+'"]').addClass('active');
        filterGroup = termGroup;
    } else {
        const liTerm = evt.currentTarget;
        const isActive = liTerm.classList.contains('active');
        const termGroup = liTerm.getAttribute('data-group');
        console.log(liTerm);
        if (isActive) {
            liTerm.classList.remove('active');
            filterGroup = shuffleInstance.ALL_ITEMS;
        } else {
            $('li.term').removeClass('active');
            liTerm.classList.add('active');
            seletizedSelect.setValue(termGroup);
            filterGroup = termGroup;
        }
    }
    shuffleInstance.filter(filterGroup);
}

function goToTop() {
    $('.goToTop').on("click", function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: 0}, 'slow');
    });
}

function monthsToShow(nbrMonths) {
    let d = new Date();
    let month = [];
    month[0] = "Janvier";
    month[1] = "Février";
    month[2] = "Mars";
    month[3] = "Avril";
    month[4] = "Mai";
    month[5] = "Juin";
    month[6] = "Juillet";
    month[7] = "Août";
    month[8] = "Septembre";
    month[9] = "Octobre";
    month[10] = "Novembre";
    month[11] = "Décembre";
    let n = d.getMonth();
    let nOne = month[n];
    let nToShow, nYear;
    let year = d.getFullYear();
    n + (nbrMonths - 1) < 12 ? nToShow = month[n + (nbrMonths - 1)] : nToShow = month[(n + (nbrMonths - 1) - 12)];
    n + (nbrMonths - 1) < 12 ?nYear = year :  nYear = year+1;
    // n < 10 ? nToShow = month[n + 2] : nToShow = month[n - 10];
    // n < 10  ? nYear = year : nYear = year+1;
    let periode = '<span class="periode">';
        periode += ' de ' +nOne;
        periode += n + (nbrMonths - 1) < 12  ? '' : ' '+year;
        periode += ' à ' +nToShow+ ' ' +nYear;
        periode += '</span>';
    return periode;
}

function getWindowHeight() {
    var windowHeight = 0;
    if (typeof(window.innerHeight) === 'number') {
        windowHeight = window.innerHeight;
    }
    else {
        if (document.documentElement && document.documentElement.clientHeight) {
            windowHeight = document.documentElement.clientHeight;
        }
        else {
            if (document.body && document.body.clientHeight) {
                windowHeight = document.body.clientHeight;
            }
        }
    }
    return windowHeight;
}
